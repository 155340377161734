@import "variables.scss";
@import "mixins.scss";

/*images page*/
.page-images {
	display: block;
	padding: 3.2rem 0;
	@media (min-width: 800px) {
		padding: 4.8rem 0;
	}
}

.main-heading {
	color: #f8c146;
	font-family: "Playwrite HR", cursive;
	font-size: 2.4rem;
	font-weight: bold;
	text-align: center;
	margin-bottom: 2rem;
	@media (min-width: 800px) {
		font-size: 3.6rem;
		margin-bottom: 4rem;
	}
}

.image-list {
	.card-deck {
		display: flex;
/*		justify-content: center;*/
		flex-wrap: wrap;
		/* margin: 0 -1rem;*/
		@media (min-width: 800px) {
			margin: 0 -1.5rem;
		}
		.card {
			position: relative;
			flex: 0 0 calc(100% / 2 - 1rem);
			margin: 0 .5rem 1rem;
			border: 0;
			border-radius: 0;
			transition: .4s all ease;
/*			transition: opacity 1.6s ease-out 0s, transform 2.2s linear 0s;*/
			@media (min-width: 800px) {
				flex: 0 0 calc(100% / 4 - 3rem);
				margin: 0 1.5rem 3rem;
				transform-origin: center center;
			}
			&:nth-child(2n+1) {
				@media (max-width: 800px) {
					transform-origin: 0% 20%;
				}
			}
			&:nth-child(2n) {
				@media (max-width: 800px) {
					transform-origin: 100% 20%;
				}
			}
			&:nth-child(4n+1) {
				@media (min-width: 800px) {
					transform-origin: 0% 50%;
				}
			}
			&:nth-child(4n) {
				@media (min-width: 800px) {
					transform-origin: 100% 50%;
				}
			}
			&:hover {
				cursor: pointer;
				position: relative;
				z-index: 1;
				transform: scale(2, 2);
				.icon-zoom {
					display: none;
				}
			}
			.card-image {
				background-color: #f5f5f5;
				@include image-ratio(100, 100);
				img {
					object-fit: cover;
				}
			}
			.icon-zoom {
				position: absolute;
				right:  .5rem;
				bottom: .5rem;
				width: 2.4rem;
				height: auto;
				cursor: pointer;
				@media (min-width: 800px) {
					display: none;
				}
			}
			.card-title {
				font-size: 2rem;
				@media (min-width: 800px) {
					font-weight: bold;
				}
			}
			.card-desc {
				font-size: 1.4rem;
				@media (min-width: 800px) {
					font-size: 1.6rem;
				}
			}
		}
	}
}