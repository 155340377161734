@import "variables.scss";

/*home page*/
.page-home {
	.main-heading {
		color: #f8c146;
		font-family: "Playwrite HR", cursive;
		font-size: 2.4rem;
		font-weight: bold;
		text-align: center;
		margin-bottom: 2rem;
		@media (min-width: 800px) {
			font-size: 3.6rem;
			margin-bottom: 4rem;
		}
	}
	.section-main {
		padding: 4rem 0;
		text-align: center;
		@media (min-width: 800px) {
			padding: 6rem 0;
		}
		p {
			font-size: 1.4rem;
			@media (min-width: 800px) {
				font-size: 1.6rem;
			}
		}
	}
	.section-content {
		padding: 4rem 0;
		text-align: center;
		@media (min-width: 800px) {
			padding: 6rem 0;
		}
		p {
			font-size: 1.8rem;
			border: 1px dashed #ddd;
			border-width: 1px 0 1px 0;
			padding: 2rem 4rem;
			margin-bottom: 0;
			@media (min-width: 800px) {
				font-size: 2rem;
				padding: 4rem 8rem;
			}
		}
	}
	.section-banner {
		position: relative;
		.banner-heading {
			background-color: rgb(0 0 0 / 25%);
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			display: flex;
		    align-items: center;
		    justify-content: center;
			padding: 2rem 1.5rem;
			text-align: center;
			h2 {
				color: #fff;
				font-size: 2.4rem;
				font-weight: 900;
				text-transform: uppercase;
				@media (min-width: 800px) {
					font-size: 4.8rem;
				}
			}
		}
	}
}