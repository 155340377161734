@import "./css/variables.scss";

body, html {
	margin: 0;
	color: #141414;
	font-family: "Source Sans 3", sans-serif;
	font-size: 10px;
	font-optical-sizing: auto;
	font-weight: 400;
	font-style: normal;
	scroll-behavior: smooth;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	padding: 0;
	margin: 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

a {
	text-decoration: none;
	@media (min-width: 800px) {
		cursor: pointer;
	}
	&:hover {
		color: teal;	
	}
}

img {
	max-width: 100%;
}

// parallax css
.parallax-item {
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	font-family: Lato, sans-serif;
	width: 100%;
}

.parallax-item h2 {
	font-size: 3rem;
	text-transform: uppercase;
	background-color: whitesmoke;
	padding: 1rem;
	border-radius: 1rem;
}

.app-banner {
	img {
		width: 100%;
	}
}

// header css
.mrp-header {
	margin-bottom: 6rem;
	@media (min-width: 800px) {
		margin-bottom: 9rem;
	}
	.navbar {
		background-color: #242527;
		padding: 1rem 0;
		@media (min-width: 800px) {
			padding: 1.5rem 0;
		}
		.navbar-brand {
			img {
				width: auto;
				height: 4rem;
				@media (min-width: 800px) {
					height: 6rem;
				}
			}
		}
		.nav-name {
			color: #fff;
			font-size: 1.4rem;
			font-weight: bold;
		}
		.offcanvas {
			max-width: 80%;
			.offcanvas-header {
				.offcanvas-title {
					font-size: 1.6rem;
					font-weight: 700;
				}
				.btn-close {
					font-size: 1.5rem;
					width: 2rem;
					height: 2rem;
				}
			}
		}
		.navbar-nav {
	  		.nav-item {
	  			margin-bottom: 1.5rem;
				@media (min-width: 800px) {
					margin-bottom: 0;
					margin-right: 3.2rem;
			  	}
				a {
				  	color: #141414;
				  	font-size: 1.6rem;
				  	font-weight: 400;
				  	text-decoration: none;
				  	text-transform: uppercase;
				  	@media (min-width: 800px) {
						color: #fff;
						font-size: 1.6rem;
					}
					&:hover {
						color: teal;
					}
				}
				&:last-child {
				 	margin-right: 0;
				}
				&.active {
					.nav-link {
						color: #f8c146;
					}
				}
	  		}
		}
  	}
}

// footer css
.mrp-footer {
	display: block;
	background-color: #242527;
	color: #fff;
	padding: 4rem 2rem;
	.mrp-logo {
		display: block;
		margin-bottom: 2rem;
		text-align: center;
		@media (min-width: 800px) {
			margin-bottom: 0;
		}
		img {
			width: auto;
			height: 6.4rem;
			@media (min-width: 800px) {
				height: 4.8rem;
			}
		}
	}
	.footer-socials {
		display: flex;
		align-items: center;
		justify-content: center;
		.btn-social {
			margin-right: 1rem;
			 margin-top: 2rem;
			@media (min-width: 800px) {
				margin-top: 0;
			 }
		  	&:last-child {
				margin-right: 0;
		  	}
		  	img {
				width: auto;
				height: 3.2rem;
		  	}
		}
  	}
  	.footer-contact {
		text-align: center;
		.btn-link {
		  	color: #fff;
		  	font-size: 1.4rem;
		  	text-decoration: none;
		  	&:hover {
				color: teal;
			}
		}
  	}
  	.footer-notes {
		font-size: 1.2rem;
		text-align: center;
		margin-top: 3rem;
		@media (min-width: 800px) {
	  		font-size: 1.4rem;
	  		padding: 0 8rem;
	  		margin-top: 6rem;
		}
  	}
}

.page-soon {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 40vh;
	text-align: center;
	@media (min-width: 800px) {
		min-height: 65vh;
	}
	h2 {
		font-size: 1.6rem;
		@media (min-width: 800px) {
			font-size: 1.8rem;
		}
	}
	h1 {
		color: #f8c146;
		font-family: "Playwrite HR", cursive;
		font-size: 3.2rem;
		font-weight: bold;
		text-align: center;
		margin-bottom: 2rem;
		@media (min-width: 800px) {
			font-size: 5.2rem;
			margin-bottom: 4rem;
		}
	}
	.btn-home {
		font-size: 1.8rem;
	}
}