@mixin image-ratio ($width : 100px, $height : 100px) {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding-top: ($height / $width) * 100%;
    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}