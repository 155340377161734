@import "variables.scss";
@import "mixins.scss";

/*preweddings page*/
.page-preweddings {
	display: block;
	padding: 3.2rem 0;
	@media (min-width: 800px) {
		padding: 4.8rem 0;
	}
}

.main-heading {
	color: #f8c146;
	font-family: "Playwrite HR", cursive;
	font-size: 2.4rem;
	font-weight: bold;
	text-align: center;
	margin-bottom: 2rem;
	@media (min-width: 800px) {
		font-size: 3.6rem;
		margin-bottom: 4rem;
	}
}

.preweddings-list {
	.card-deck {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		/* margin: 0 -1rem;*/
		@media (min-width: 800px) {
			margin: 0 -1.5rem;
		}
		.card {
			border: 0;
			border-radius: 0;
			flex: 0 0 calc(100% / 2 - 1rem);
			margin: 0 .5rem 1rem;
			@media (min-width: 800px) {
				flex: 0 0 calc(100% / 4 - 3rem);
				margin: 0 1.5rem 3rem
			}
			.card-image {
				background-color: #f5f5f5;
				@include image-ratio(100, 100);
				img {
					object-fit: cover;
				}
			}
			.card-body {
				text-align: center;
			}
			.card-title {
				font-size: 2rem;
				@media (min-width: 800px) {
					font-weight: bold;
				}
			}
			.card-desc {
				font-size: 1.4rem;
				@media (min-width: 800px) {
					font-size: 1.6rem;
				}
			}
		}
	}
}