@import "variables.scss";
@import "mixins.scss";

/*contact page*/
.page-contact {
	display: block;
	padding: 4rem 0;
	text-align: center;
	@media (min-width: 800px) {
		padding: 6rem 0;
	}
	.main-heading {
		color: #f8c146;
		font-family: "Playwrite HR", cursive;
		font-size: 2.4rem;
		font-weight: bold;
		text-align: center;
		margin-bottom: 2rem;
		@media (min-width: 800px) {
			font-size: 3.6rem;
			margin-bottom: 4rem;
		}
	}
	.sub-heading {
		color: #f8c146;
		font-family: "Playwrite HR", cursive;
		font-size: 2rem;
		font-weight: bold;
		text-align: center;
		margin-bottom: .8rem;
		@media (min-width: 800px) {
			font-size: 2.4rem;
		}
	}
	.contact-desc {
		p {
			font-size: 1.6rem;
			@media (min-width: 800px) {
				font-size: 1.4rem;
			}
		}
	}
	.contact-details {
		h2 {
			font-size: 1.6rem;
			font-weight: bold;
			@media (min-width: 800px) {
				font-size: 2rem;
			}
		}
		p {
			font-size: 1.4rem;
			@media (min-width: 800px) {
				font-size: 1.6rem;
			}
		}
		a {
			color: #141414;
			text-decoration: none;
			font-size: 1.4rem;
			@media (min-width: 800px) {
				font-size: 1.6rem;
				cursor: pointer;
			}
			&:hover {
				color: teal;
				text-decoration: underline;
			}
		}
	}
	.team-list {
		.card-deck {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			/* margin: 0 -1rem;*/
			@media (min-width: 800px) {
				margin: 0 -1.5rem;
			}
			.card {
				flex: 0 0 calc(100% / 2 - 1rem);
				margin: 0 .5rem 1rem;
				border-radius: .8rem;
				overflow: hidden;
				@media (min-width: 800px) {
					flex: 0 0 calc(100% / 4 - 3rem);
					margin: 0 1.5rem 3rem
				}
				.card-image {
					background-color: #f5f5f5;
					@include image-ratio(100, 100);
				}
				.card-title {
					font-size: 2rem;
					@media (min-width: 800px) {
						font-weight: bold;
					}
				}
				.card-desc {
					font-size: 1.4rem;
					@media (min-width: 800px) {
						font-size: 1.6rem;
					}
				}
			}
		}
	}
}